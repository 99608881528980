import React from 'react';
import CommentIcon from '../../assets/smallComment.svg';
import {formatNumber} from '../../common/utils';

import styles from './styles.module.css';

interface Props {
  value: number;
}

// As per design, the parent is the anchor link so we don't do nested anchors
const CommentButton = ({value}: Props) => {
  return (
    <div className={styles.container} data-testid="comment">
      <div className={styles.icon}>
        <CommentIcon />
      </div>
      <CommentText value={value} />
    </div>
  );
};

export default CommentButton;

interface CommentTextProps {
  value: number;
}

const CommentText = ({value}: CommentTextProps) => {
  if (value > 1) {
    return (
      <span className={styles.text}>
        <b>{formatNumber(value)}</b> comments
      </span>
    );
  }

  return (
    <span className={styles.text}>
      <b>{value}</b> comment
    </span>
  );
};
