import React from 'react';
import EmptyAvatar from '../../assets/avatar.svg';

import styles from './styles.module.css';

interface Props {
  username: string;
}

const CommentUsernameAvatar = ({username}: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.avatarContainer}>
        <div className={styles.emptyAvatar}>
          <EmptyAvatar />
        </div>
      </div>
      <span className={styles.username}>{username}</span>
    </div>
  );
};

export default CommentUsernameAvatar;
