import {XpModuleEnvironment} from '@mol-fe/mol-fe-registry-types';
import React, {useEffect, useRef, useState} from 'react';
import CommentIcon from './assets/comment.svg';
import {getArticleCommentList} from './common/articleComment';
import ArticleDesktop from './components/ArticleDesktop';
import ArticleMobile from './components/ArticleMobile';
import {useMount} from './hooks/useMount';
import {ArticleCommentPreview} from './types';

import styles from './App.module.css';

interface Props {
  env: XpModuleEnvironment;
  ids: string[];
  isSmall: boolean;
}

const App = ({env, ids, isSmall}: Props) => {
  const ref = useRef(null);
  const [inView, setInView] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [previews, setPreviews] = useState<ArticleCommentPreview[]>([]);
  const isMounted = useMount();

  const getArticlesPreviews = async () => {
    if (isLoading || ids.length === 0 || previews.length > 0) {
      return;
    }

    setLoading(true);
    await getArticleCommentList(env, ids).then((result) => {
      if (isMounted.current) {
        setPreviews(result);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (inView) {
      getArticlesPreviews().catch(() => {});
    }
  }, [inView]);

  useEffect(() => {
    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          setInView(true);
        }
      }
    };
    const observer = new IntersectionObserver(handleIntersect, {
      rootMargin: '1000px'
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className={styles.container} ref={ref} data-track-module="article-comment-previews">
      <div className={styles.headline}>
        <p className={styles.title}>YOUR TOP COMMENTS</p>
        <div className={styles.commentIcon}>
          <CommentIcon />
        </div>
      </div>

      <ArticleList isSmall={isSmall} previews={previews} />
    </div>
  );
};

export default App;

interface ArticleListProps {
  isSmall: boolean;
  previews: ArticleCommentPreview[];
}

const ArticleList = ({isSmall, previews}: ArticleListProps) => {
  if (isSmall) {
    return (
      <div className={styles.articleList}>
        {previews.map((preview) => (
          <ArticleMobile key={preview.id} preview={preview} />
        ))}
      </div>
    );
  }

  return (
    <div className={styles.articleList}>
      {previews.map((preview) => (
        <ArticleDesktop key={preview.id} preview={preview} />
      ))}
    </div>
  );
};
