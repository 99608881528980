import {XpModuleEnvironment} from '@mol-fe/mol-fe-registry-types';
import {getOpenWebComments, getOpenWebMessageCount} from '../backend/openwebApi';
import {ArticleCommentPreview} from '../types';

export const getOWBestComment = async (
  env: XpModuleEnvironment,
  id: number,
  articleUrl: string
): Promise<ArticleCommentPreview['comment'] | null> => {
  const conversation = await getOpenWebComments(env, id);

  if (!conversation || conversation.comments.length < 1) {
    return null;
  }

  const comment = conversation.comments[0];
  const {users} = conversation;
  const user = users[comment.user_id] ?? null;

  if (!user || comment.content.length < 1) {
    return null;
  }

  const {content, rank} = comment;

  return {
    downVotes: rank.ranks_down,
    previewText: content[0].text,
    upVotes: rank.ranks_up,
    // Jumping specific to comment not supported in openweb
    url: `${articleUrl}#comments`,
    username: user.display_name
  };
};

export const getOWArticleCount = async (env: XpModuleEnvironment, id: number): Promise<number> => {
  const message = await getOpenWebMessageCount(env, id);

  if (message && message.messages_count) {
    return message.messages_count[id] ?? 0;
  }

  return 0;
};
