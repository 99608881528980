import classnames from 'classnames';
import React from 'react';
import Arrow from '../../assets/voteArrow.svg';
import {formatNumber} from '../../common/utils';

import styles from './styles.module.css';

interface Props {
  value: number;
  type: 'up' | 'down';
}

const CommentVote = ({value, type}: Props) => {
  return (
    <div className={classnames(styles.container, styles[type])} data-testid={type}>
      <div className={styles.icon}>
        <Arrow />
      </div>
      <span className={styles.text}>{formatNumber(value)}</span>
    </div>
  );
};

export default CommentVote;
