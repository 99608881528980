import React from 'react';
import {ArticleCommentPreview} from '../../types';
import Bubble from '../Bubble';
import CommentMetrics from '../CommentMetrics';
import CommentUsernameAvatar from '../CommentUsernameAvatar';
import Paragraph from '../Paragraph';

import styles from './styles.module.css';

interface Props {
  preview: ArticleCommentPreview;
}

const ArticleDesktop = ({preview}: Props) => {
  const {comment, commentCount, imageUrl, previewText, url} = preview;
  const {url: commentUrl, upVotes, downVotes, previewText: commentText, username} = comment;

  return (
    <Bubble>
      <div className={styles.container}>
        <a href={url} target="_top">
          <div className={styles.preview}>
            <img className={styles.previewImage} src={imageUrl} />
            <div className={styles.previewText}>
              <Paragraph value={previewText} />
            </div>
          </div>
        </a>

        <a href={commentUrl} className={styles.comment} target="_top">
          <div className={styles.commentText}>
            <CommentUsernameAvatar username={username} />
            <Paragraph value={commentText} />
          </div>

          <CommentMetrics upVotes={upVotes} downVotes={downVotes} commentCount={commentCount} />
        </a>
      </div>
    </Bubble>
  );
};

export default ArticleDesktop;
