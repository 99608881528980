import {Platforms, XpModuleEnvironment} from '@mol-fe/mol-fe-registry-types';
import React from 'react';
import {render} from 'react-dom';
import {SizeMe} from 'react-sizeme';
import App from './App';
import {DMWindow} from './types';
import {getBreakpoint} from './common/utils';

declare const window: DMWindow & typeof globalThis;
const DM = window.DM;

const asArticlesIds = (ids: string) => ids.split(',');

const initWidget = async () => {
  Array.from(document.querySelectorAll('[data-mol-fe-xpmodule-article-comment-previews]')).forEach(async (element) => {
    const dataset = (element as HTMLDivElement).dataset;
    const env = (dataset.environment ?? 'production') as XpModuleEnvironment;
    const ids = asArticlesIds(dataset.articleids ?? '');
    const platform = (dataset.platform ?? 'mol.web.mobile') as Platforms;

    if (platform === 'mol.web.mobile') {
      render(<App env={env} ids={ids} isSmall={true} />, element);
    } else {
      render(
        <SizeMe>{({size: {width}}) => <App env={env} ids={ids} isSmall={getBreakpoint(width) === 'small'} />}</SizeMe>,
        element
      );
    }
  });
};

const domReady = async (): Promise<void> => {
  return new Promise<void>((resolve, _reject) => {
    if (document.readyState === 'loading') {
      const handler = () => {
        resolve();
        document.removeEventListener('DOMContentLoaded', handler);
      };

      document.addEventListener('DOMContentLoaded', handler);
    } else {
      resolve();
    }
  });
};

const init = async () => {
  try {
    await domReady();
    if (DM?.later) {
      await DM.later('DOM_READY');
    }
    await initWidget();
  } catch (error) {
    // handle error
  }
};

// eslint-disable-next-line @typescript-eslint/no-floating-promises
init();
