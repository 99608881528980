import React from 'react';
import BubbleTail from '../../assets/bubbleTail.svg';

import styles from './styles.module.css';

interface Props {
  children: React.ReactNode;
}
const Bubble = ({children}: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.bubble}>{children}</div>

      <div className={styles.tail}>
        <BubbleTail />
      </div>
    </div>
  );
};

export default Bubble;
