import React from 'react';

import styles from './styles.module.css';

interface Props {
  value: string;
}

const Paragraph = ({value}: Props) => {
  return <div className={styles.container} dangerouslySetInnerHTML={{__html: value}} />;
};

export default Paragraph;
