import React from 'react';
import CommentVote from '../CommentVote';
import CommentButton from '../CommentButton';

import styles from './styles.module.css';

interface Props {
  commentCount: number;
  downVotes: number;
  upVotes: number;
}

const CommentMetrics = ({commentCount, downVotes, upVotes}: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.votes}>
        <CommentVote type="up" value={upVotes} />
        <CommentVote type="down" value={downVotes} />
      </div>

      <CommentButton value={commentCount} />
    </div>
  );
};

export default CommentMetrics;
