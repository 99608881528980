import {XpModuleEnvironment} from '@mol-fe/mol-fe-registry-types';
import axios from 'axios';
import {logger} from '../common/logger';

const TIMEOUT_MS = 3000;

interface Comment {
  conversation_id: string;
  root_comment: string;
  parent_id: string;
  depth: number;
  id: string;
  user_id: string;
  written_at: number;
  time: number;
  replies_count: number;
  offset: number;
  has_next: boolean;
  edited: boolean;
  status: string;
  rank: {ranks_up: number; ranks_down: number; ranked_by_current_user: number};
  replies: unknown;
  content: [
    {
      id: string;
      text: string;
      type: string;
    }
  ];
  tags: unknown;
  deleted: boolean;
  published: boolean;
  rank_score: number;
  stars: number;
  type: string;
  metadata: unknown;
  flags: unknown;
  featured: boolean;
  top_featured: boolean;
  best_score: number;
  user_display_name: string;
  rt_updated_at: number;
  total_replies_count: number;
  user_reputation: number;
  app_bundle_id: string;
  data_best_score: number;
}

interface ConversationPayload {
  conversation: {
    comments: Comment[];
    users: Record<
      string,
      {
        display_name: string;
      }
    >;
  };
}

const getSpotId = (env: XpModuleEnvironment) => {
  return env === 'production' ? 'sp_jhHPoiRK' : 'sp_IAjfVigC';
};

export const getOpenWebComments = async (
  env: XpModuleEnvironment,
  id: number
): Promise<ConversationPayload['conversation'] | null> => {
  try {
    const result = await axios.post(
      'https://api-2-0.spot.im/v1.0.0/conversation/tab/popular',
      {
        child_count: 1,
        count: 1,
        depth: 1,
        offset: 0,
        sort_by: 'best'
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-post-id': id.toString(),
          'x-spot-id': getSpotId(env)
        },
        timeout: TIMEOUT_MS
      }
    );

    if (result.status === 200) {
      const value = await result.data;
      const payload = value as ConversationPayload;

      return payload.conversation;
    }
  } catch (err) {
    logger.error(err);
  }

  return null;
};

interface CountPayload {
  spot_id: string;
  messages_count: Record<string, number>;
}

export const getOpenWebMessageCount = async (env: XpModuleEnvironment, id: number): Promise<CountPayload | null> => {
  try {
    const result = await axios.get(
      `https://open-api.spot.im/v1/messages-count?spot_id=${getSpotId(env)}&posts_ids=${id}`
    );

    if (result.status === 200) {
      const value = (await result.data) as CountPayload;

      return value;
    }
  } catch (err) {
    logger.error(err);
  }

  return null;
};
