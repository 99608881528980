import {XpModuleEnvironment} from '@mol-fe/mol-fe-registry-types';

export const getBaseUrl = (env: XpModuleEnvironment) => {
  if (env === 'production') {
    return 'https://www.dailymail.co.uk';
  }

  return 'https://www.dailymailint.co.uk';
};

const MEDIUM_WIDTH = 500;
const LARGE_WIDTH = 830;

type Breakpoint = 'small' | 'medium' | 'large';

export const getBreakpoint = (width: number | null): Breakpoint => {
  if (!width) {
    return 'small';
  }

  if (width > LARGE_WIDTH) {
    return 'large';
  }

  if (width > MEDIUM_WIDTH) {
    return 'medium';
  }

  return 'small';
};

export const formatNumber = (value: number): string => {
  if (value < 1000) {
    return value.toString();
  }

  const updatedValue = value / 1000;
  const roundedValue = Math.round(updatedValue * 10) / 10; // 1 dp

  return `${roundedValue}K`;
};
