import {XpModuleEnvironment} from '@mol-fe/mol-fe-registry-types';
import {Article} from '@mol-utils/ts-types-mailonline';
import axios from 'axios';
import {logger} from '../common/logger';
import {getBaseUrl} from '../common/utils';

interface ArticleData extends Article {
  articleURL: string;
  shareImageUrl: string;
  socialHeadline: string;
  puffThumbUrl: string;
  mobileThumbUrl: string;
  commentsCount: number;
  shareCount: number;
}

export const getArticlePreviews = async (env: XpModuleEnvironment, articlesIds: string[]): Promise<ArticleData[]> => {
  const baseUrl = getBaseUrl(env);
  const ids = articlesIds.join(',');

  try {
    const result = await axios.get(`${baseUrl}/api/mol-fe-feeds/v2/articles/previews/${ids}`);

    if (result.status === 200) {
      const value = await result.data;

      return value.articles as ArticleData[];
    }
  } catch (err) {
    logger.error(err);
  }

  return [];
};
