import {XpModuleEnvironment} from '@mol-fe/mol-fe-registry-types';
import {getArticlePreviews} from '../backend/articleApi';
import {ArticleCommentPreview} from '../types';
import {getOWArticleCount, getOWBestComment} from './commentPreview';
import {getBaseUrl} from './utils';

export const getArticleCommentList = async (
  env: XpModuleEnvironment,
  ids: string[]
): Promise<ArticleCommentPreview[]> => {
  const articleList = await getArticlePreviews(env, ids);

  const baseUrl = getBaseUrl(env);

  const promises = articleList.map(async (article) => {
    const {articleId, articleURL, socialHeadline, puffThumbUrl} = article;

    const url = `${baseUrl}${articleURL}`;

    const [comment, count] = await Promise.all([
      getOWBestComment(env, articleId, url),
      getOWArticleCount(env, articleId)
    ]);

    return asArticleCommentPreview(articleId, url, puffThumbUrl, count, socialHeadline, comment);
  });

  const articleComments = await Promise.all(promises);

  return articleComments.filter((item): item is ArticleCommentPreview => !!item);
};

const asArticleCommentPreview = (
  id: number,
  articleUrl: string,
  imageUrl: string,
  commentCount: number,
  headline: string,
  comment: ArticleCommentPreview['comment'] | null
): ArticleCommentPreview => {
  return {
    comment: comment
      ? comment
      : // If there is no comment, we show an empty placeholder.
        // Editorial will own this responsibility to ensure this doesn't happen.
        {
          downVotes: 0,
          previewText: '',
          upVotes: 0,
          url: `${articleUrl}#comments`,
          username: 'Anonymous'
        },
    commentCount,
    id,
    imageUrl,
    previewText: headline,
    url: articleUrl
  };
};
