import React from 'react';
import {ArticleCommentPreview} from '../../types';
import Bubble from '../Bubble';
import CommentMetrics from '../CommentMetrics';
import CommentUsernameAvatar from '../CommentUsernameAvatar';
import Paragraph from '../Paragraph';

import styles from './styles.module.css';

interface Props {
  preview: ArticleCommentPreview;
}

const ArticleMobile = ({preview}: Props) => {
  const {comment, commentCount, imageUrl, previewText, url: previewUrl} = preview;
  const {url, upVotes, downVotes, previewText: commentText, username} = comment;

  return (
    <Bubble>
      <div className={styles.container}>
        <a href={previewUrl} target="_top">
          <div className={styles.preview}>
            <img className={styles.previewImage} src={imageUrl} />
            <div className={styles.previewText}>
              <Paragraph value={previewText} />
            </div>
          </div>
        </a>

        <a href={url} target="_top">
          <CommentUsernameAvatar username={username} />

          <div className={styles.comment}>
            <Paragraph value={commentText} />

            <CommentMetrics upVotes={upVotes} downVotes={downVotes} commentCount={commentCount} />
          </div>
        </a>
      </div>
    </Bubble>
  );
};

export default ArticleMobile;
