import {useEffect, useRef} from 'react';

// Use this to prevent unmounted memory leak
export const useMount = () => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
};
